import React, { useEffect, useRef, useState } from 'react'
import type { ImportPrizesProps, ImportedPrize } from './ImportPrizesAndApplications.types'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { excelExt } from 'config/extensions'
import { Can } from 'config/ability'
import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import type { AxiosResponse } from 'axios'
import ErrorView from 'components/ErrorView'
import { useNavigate } from 'react-router-dom'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import RequestStatus from 'components/RequestStatus/RequestStatus'
import type { PrizeToImport } from 'types/Prize'
import Paper from '@mui/material/Paper'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import List from '@mui/material/List'
import PageLoader from 'components/PageLoader'
import { PrizeElement } from '../ImportPrizes'

const ImportPrizesAndApplications = ({ market }: ImportPrizesProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const { token, refreshToken } = useAuthContext()
  const importInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFileToImport, setSelectedFileToImport] = useState<undefined | File>()

  const { data: prizes, isLoading: isPrizesLoading, refetch } = useQueryGet<PrizeToImport[]>({
    queryKey: ['all-prizes', token, market],
    endpoint: 'prize-draw',
    options: { params: { marketId: market?.id ?? '' } }
  })

  const { mutate, isLoading, data, reset } = useMutation(async (data: FormData) => await axiosPost<ImportedPrize | { error: AxiosResponse | undefined }>(token, refreshToken, 'applications/import', data), {
    onSuccess: (data, ctx) => {
      if (data !== undefined && !('error' in data)) {
        changeMessage('Success', 'success', reset)
        navigate('/applications')
        void refetch({ exact: false, queryKey: ['all-prizes'] })
      }
    }
  })

  useEffect(() => {
    reset()
    setSelectedFileToImport(undefined)
  }, [market])

  useEffect(() => {
    // @ts-expect-error-next-line
    if (data?.error?.message !== undefined && typeof data?.error?.message === 'string') {
      // @ts-expect-error-next-line
      changeMessage(<span>{data.error.message}</span>, 'error', () => {
        reset()
      })
    }
  }, [data])

  const handleImportPrizes = (): void => {
    importInputRef.current?.click()
  }

  const handleSendPrizes = (): void => {
    if (selectedFileToImport !== undefined) {
      const fd = new FormData()
      if (market !== undefined) {
        fd.append('marketId', market.id.toString())
        fd.append('country', market.name)
      }
      fd.append('file', selectedFileToImport)
      mutate(fd)
    }
  }

  if (data !== undefined && 'error' in data && typeof data?.error !== 'string' && data?.error !== undefined && 'status' in data?.error) {
    if (data?.error?.status === 401) {
      return <ErrorView type='privilages' />
    }

    if (data?.error?.status === 401) {
      return <ErrorView type='account' />
    }
  }

  if (prizes === undefined || 'error' in prizes) {
    return <RequestStatus data={prizes} isLoading={isPrizesLoading} />
  }

  return (
    <>
      <Box>
        <Paper sx={{ m: 1, p: 4 }}>
          <Typography fontWeight={700} >{t('prize.winners')} ({t('prize.clickToCopyName')}) :</Typography>
          <List sx={{ width: '100%', maxWidth: 400 }}>
            {prizes.map((prize) => <PrizeElement key={prize.prizeGroup.id} prize={prize} />)}
          </List>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', mt: 2 }}>
            <input
              ref={importInputRef}
              disabled={(data !== undefined && data !== null && !('error' in data))}
              accept={excelExt.join(', ')}
              onChange={(e) => { setSelectedFileToImport(e.target.files?.[0]) }}
              type='file'
              style={{ position: 'absolute', width: '1px', height: '1px', opacity: 0 }}
            />
            <Can I='upload' a='Import prizes'>
              <ButtonGroup>
                <Button
                  onClick={handleImportPrizes}
                  type='button'
                  variant='contained'
                  color='primary'
                  disabled={(data !== undefined && data !== null && !('error' in data))}
                  startIcon={<VerticalAlignTopOutlinedIcon />}
                >
                  {t(selectedFileToImport !== undefined ? 'common.import' : 'common.importPrizesFile')}
                  &nbsp;
                  {selectedFileToImport !== undefined ? selectedFileToImport.name.slice(0, 20) : null}
                </Button>
                {selectedFileToImport !== undefined
                  ? <Button
                    onClick={handleSendPrizes}
                    type='button'
                    variant='contained'
                    disabled={(data !== undefined && data !== null && !('error' in data))}
                    color='success'
                    startIcon={<DoneOutlinedIcon />}
                  >
                    {t('common.next')}
                  </Button>
                  : null}
              </ButtonGroup>
            </Can>
          </Box>
        </Paper>
      </Box>

      {isLoading && <PageLoader />}
    </>
  )
}

export default ImportPrizesAndApplications
